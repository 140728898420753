import React from 'react';
import PropTypes from 'prop-types';
import DaniaAdmin from 'components/molecules/DaniaAdmin/DaniaAdmin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  pobierzWszytkieDaniaAction,
  zapiszDanie,
  pobierzKategorieDanAction,
} from 'services/admin/daniaServices';
import { addAlert } from 'actions/alertActions';
import Obraz from 'components/atoms/Obraz/Obraz';
import plus from 'assets/svg/plusik.svg';
import AdminTemplate from 'templates/AdminTemplate';
import {
  Table,
  AdminWrapperA,
  StyledTabListA,
  ArrowContC,
  ArrowA,
  Content,
  Cell,
  AddNew,
  SidebarHeader,
  // StyledSelect,
  Tab,
  EditInput,
  InputDania,
} from './style';

class AdminDania extends React.Component {
  _isMounted = false;

  state = {
    wybranaKategoria: 0,
    filteredDania: [],
    noweDanie: {
      nazwa: '',
      rodzaj: '',
      cena: '',
      kodDania: '',
      kategoriaId: 0,
      wege: false,
      navNone: false,
    },
  };

  componentDidMount() {
    this._isMounted = true;
    const { pobierzDania, pobierzKategorieDan } = this.props;
    pobierzDania().then(() => {
      this.filtrujDania(0);
    });
    pobierzKategorieDan();
  }

  componentDidUpdate(prevProps) {
    const { dania } = this.props;
    const { wybranaKategoria } = this.state;
    if (prevProps.dania.length !== dania.length) {
      this.filtrujDania(wybranaKategoria);
    }
  }

  // componentDidUpdate(prevProps, prevState) {}
  componentWillUnmount() {
    this._isMounted = false;
  }

  filtrujDania = kategoriaId => {
    const { dania } = this.props;
    let filteredDania = dania;
    if (kategoriaId > 0) {
      filteredDania = filteredDania.filter(danie => danie.kategoriaId === kategoriaId);
    }
    if (this._isMounted) {
      this.setState({ filteredDania, wybranaKategoria: kategoriaId });
    }
  };

  onMenuItemClicked = () => {
    this.setState(prevState => ({ navNone: !prevState.navNone }));
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === 'checkbox') {
      const checked = e.target.checked;
      this.setState(prevState => ({
        ...prevState,
        noweDanie: {
          ...prevState.noweDanie,
          [name]: checked,
        },
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        noweDanie: {
          ...prevState.noweDanie,
          [name]: value,
        },
      }));
    }
  }

  dodajDanie() {
    const { zapiszDanieService } = this.props;
    const { noweDanie } = this.state;

    let err = '';
    try {
      if (noweDanie.nazwa.length === 0) {
        err = 'Nazwa dania jest wymagana';
        throw err;
      }
      if (
        noweDanie.cena.length === 0 ||
        (!parseInt(noweDanie.cena, 10) && noweDanie.cena !== '0')
      ) {
        err = 'Błędna cena';
        throw err;
      }
      if (noweDanie.kodDania.length === 0) {
        err = 'Kod dania jest wymagana';
        throw err;
      }
      if (noweDanie.kategoriaId === 0) {
        err = 'Brak wybranej kategorii';
        throw err;
      }

      zapiszDanieService(noweDanie).then(() => {
        if (this._isMounted) {
          this.setState({
            noweDanie: {
              nazwa: '',
              kategoriaId: 0,
              cena: '',
              wege: '',
              kodDania: '',
            },
          });
        }
      });
    } catch (error) {
      addAlert({
        title: 'Błąd!',
        message: error,
        type: 'danger',
      });
    }
  }

  render() {
    const { kategorieDan } = this.props;
    const { noweDanie, wybranaKategoria, filteredDania, navNone } = this.state;
    const { nazwa, kategoriaId, cena, wege, kodDania } = noweDanie;

    return (
      <AdminTemplate>
        <AdminWrapperA>
          <StyledTabListA
            style={{
              transition: '1s',
              left: navNone ? 0 : '-300px',
            }}
            responsive
          >
            <>
              <SidebarHeader>Filtry</SidebarHeader>
              <Tab onClick={() => this.filtrujDania(0)} active={wybranaKategoria === 0}>
                Wszystkie
              </Tab>
              {kategorieDan.map(item => (
                <Tab
                  onClick={() => this.filtrujDania(item.id)}
                  active={item.id === wybranaKategoria}
                  key={item.id}
                >
                  {item.nazwa}
                </Tab>
              ))}
            </>
          </StyledTabListA>
          <ArrowContC
            onClick={this.onMenuItemClicked}
            style={{
              transition: '1s',
              left: navNone ? '300px' : '0px',
            }}
          >
            <ArrowA
              style={{
                transition: '1s',
                transform: navNone ? `rotate(135deg)` : `rotate(-45deg)`,
              }}
            />
          </ArrowContC>
          <Content>
            <Table scrolled view="dania">
              <Cell center head thin>
                L.P.
              </Cell>
              <Cell head>Nazwa</Cell>
              <Cell center head thin>
                Rodzaj
              </Cell>
              <Cell center head>
                Kategoria
              </Cell>
              <Cell center head thin>
                Cena
              </Cell>
              <Cell head>Kod Dania</Cell>
              <Cell center head thin>
                Wege
              </Cell>
              <Cell center head thin>
                Status
              </Cell>
              <Cell center head thin>
                Pozycja
              </Cell>
              <Cell center head thin>
                Dodaj/Usuń
              </Cell>
              <Cell />
              <Cell>
                <InputDania
                  type="text"
                  name="nazwa"
                  value={nazwa}
                  onChange={e => this.handleInputChange(e)}
                />
              </Cell>
              <Cell center thin />
              <Cell center>
                <select
                  name="kategoriaId"
                  value={kategoriaId}
                  onChange={e => this.handleInputChange(e)}
                >
                  <option value="">Wybierz</option>
                  {kategorieDan.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nazwa}
                    </option>
                  ))}
                </select>
              </Cell>
              <Cell center thin>
                <InputDania
                  center
                  type="text"
                  name="cena"
                  value={cena}
                  onChange={e => this.handleInputChange(e)}
                />
              </Cell>
              <Cell center>
                <InputDania
                  type="text"
                  name="kodDania"
                  value={kodDania}
                  onChange={e => this.handleInputChange(e)}
                />
              </Cell>
              <Cell center thin>
                <EditInput
                  type="checkbox"
                  name="wege"
                  onChange={e => this.handleInputChange(e)}
                  checked={wege}
                />
              </Cell>
              <Cell />
              <Cell />
              <Cell center thin>
                <AddNew onClick={e => this.dodajDanie(e)}>
                  <Obraz src={plus} alt="Dodaj danie" />
                </AddNew>
              </Cell>
              {filteredDania.map(item => (
                <DaniaAdmin
                  key={`${item.id}_${filteredDania.indexOf(item) + 1}_${item.kategoriaId}`}
                  index={filteredDania.indexOf(item) + 1}
                  {...item}
                />
              ))}
            </Table>
          </Content>
        </AdminWrapperA>
      </AdminTemplate>
    );
  }
}

AdminDania.propTypes = {
  dania: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  kategorieDan: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  noweDanie: PropTypes.shape({
    nazwa: PropTypes.string.isRequired,
  }),
  // pendingPobierz: PropTypes.bool,
  alertError: PropTypes.func,
  pobierzDania: PropTypes.func,
  zapiszDanieService: PropTypes.func,
  pobierzKategorieDan: PropTypes.func,
};

AdminDania.defaultProps = {
  dania: [],
  noweDanie: null,
  kategorieDan: [],
  zapiszDanieService: null,
  pobierzKategorieDan: null,
  // pendingPobierz: false,
  alertError: null,
  pobierzDania: null,
};

const mapStateToProps = state => ({
  // error: state.zamowieniaReducer.error,
  kategorieDan: state.daniaReducer.kategorieDan,
  dania: state.daniaReducer.dania,
  // pendingPobierz: state.zamowieniaReducer.pendingWyslij,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pobierzDania: pobierzWszytkieDaniaAction,
      pobierzKategorieDan: pobierzKategorieDanAction,
      zapiszDanieService: zapiszDanie,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminDania);
