import zupa from 'assets/svg/zupa.svg';
import danie from 'assets/svg/daniednia.svg';
import salatka from 'assets/svg/salatka.svg';
import Wege from 'assets/svg/wege.svg';
import Kura from 'assets/svg/kura.svg';
import NaSlodko from 'assets/svg/na_slodko.svg';
import Wieprz from 'assets/svg/wieprz.svg';
import InneMiesne from 'assets/svg/inne_miesa.svg';
import Ryba from 'assets/svg/ryba.svg';
import FastFood from 'assets/svg/fast_food.svg';
import DodatkiDoDan from 'assets/svg/dodatki_skrobiowe.svg';
import NapojeGorace from 'assets/svg/napoje_gorace.svg';
import NapojeZimne from 'assets/svg/napoje_zimne.svg';
import Przekaski from 'assets/svg/przekaski.svg';
import Desser from 'assets/svg/Desser2.svg';

const obrazkiDan = {
  zupa,
  danie,
  salatka,
  Zupy: zupa,
  Sałatka: salatka,
  'Fast Food': FastFood,
  'Na słodko': NaSlodko,
  'Inne mięsne': InneMiesne,
  'Dodatki do dań': DodatkiDoDan,
  'Napoje Gorące': NapojeGorace,
  'Napoje Zimne': NapojeZimne,
  // 'Warzywne d.': Wege,
  Deser: Desser,
  Przekąski: Przekaski,
  Wege,
  dodatekW: Wege,
  Kura,
  Jednogarnkowe: danie,
  Wieprz,
  Ryba,
};

export default obrazkiDan;
