import styled, { css } from 'styled-components';
import Select from 'react-select';
import Button from 'components/atoms/Button/Button';
import Heading from 'components/atoms/Heading/Heading';
import KontrahenciAdmin from 'components/organisms/KontrahenciAdmin/KontrahenciAdmin';
import { Link } from 'react-router-dom';
import { Tab as Tabik, TabList, TabPanel } from 'react-tabs';
import { theme } from 'theme/adminTheme';

const handleGridTemplate = view => {
  switch (view) {
    case 'menu':
      return `5rem 6rem auto 14rem 14rem 10rem 6rem 8rem 7rem 12rem 7rem`;
    case 'dania':
      return `5rem 20rem 6rem 15rem 8rem 12rem 8rem 8rem 7rem  10rem`;
    case 'kontrahent':
      return `5rem 24rem 22rem 20rem 15rem 15rem 10rem`;
    case 'kontrahenci':
      return `5rem 24rem 22rem 20rem 20rem 15rem 10rem 10rem`;
    case 'edytuj':
      return `15rem 24rem 12rem 24rem 15rem 12rem 14rem 16rem 5rem`;
    case 'zamowienia':
      return `5rem 20rem 40rem 6rem 8rem 8rem`;
    case 'uzytkownik':
      return `15rem 15rem 20rem 20rem 10rem 15rem 10rem 5rem `;
    default:
      return '5rem 6rem auto 14rem 10rem 6rem 8rem 7rem 12rem 7rem';
  }
};

export const Label = styled.label`
  margin-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  padding-left: ${({ row }) => (row ? '2rem' : '0')};
  span {
    padding-top: 0;
    padding-left: 2rem;
  }
`;
export const Input = styled.input`
  border: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  width: ${({ center }) => (center ? 'auto' : '100%')};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  &[name='cena'] {
    width: 7rem;
  }
  &:hover {
    border-bottom-color: #80cf26;
  }
  &:focus {
    outline: none;
    border-color: #80cf26;
    background-color: rgba(128, 207, 38, 0.4);
  }
`;

export const InputDania = styled(Input)`
  border: 1px solid rgba(128, 207, 38, 0.2);
  border-bottom-color: rgba(128, 207, 38, 1);
  &[name='cena'] {
    width: 7rem;
  }
  &:focus {
    outline: none;
    border-color: #80cf26;
    background-color: rgba(128, 207, 38, 0.4);
  }
`;
export const StyledTabList = styled(TabList)`
  padding: 2rem 3.8rem 0 2.2rem;
  width: 16.6666%;
  height: 100%;
  min-height: calc(100vh - 8rem);
  background: #f5e8df;
  margin: 0;
  list-style: none;

  ${({ responsive }) =>
    responsive &&
    css`
      @media screen and (max-width: ${theme.size.laptop}) {
        display: none;
      }
    `}
`;

export const StyledHeading = styled.div`
  margin-top: 2rem;
  padding-left: 3rem;
`;

export const StyledSelectWrapper = styled.div`
  display: flex;
  padding-left: 2rem;
  margin-top: 2rem;
  align-items: center;
`;

export const StyledTab = styled(Tabik)`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.25em;
  color: #0b0b0b;
  padding: 1.2rem 2rem;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease;
  &.react-tabs__tab--selected {
    background: #000;
    border-color: #000;
    color: #fff;
    border-radius: 6px;
  }
  &:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.3);
  }
  & + & {
    margin-top: 0.4rem;
  }
`;

export const StyledTabPanel = styled(TabPanel)`
  padding-top: 10rem;
  width: 80%;
  height: 100%;
  min-height: calc(100vh - 8rem);
  padding-left: 3.5rem;
  display: none;
  ${({ responsive }) =>
    responsive &&
    css`
      @media screen and (max-width: ${theme.size.laptop}) {
        width: 100%;
        padding-top: 10rem;
      }
    `}
`;

export const StyledTabPanelA = styled(TabPanel)`
  padding-top: 2rem;
  width: 80%;
  height: 100%;
  min-height: calc(100vh - 8rem);
  padding-left: 30px;
  display: none;
  padding-bottom: 5rem;
  ${({ responsive }) =>
    responsive &&
    css`
      @media screen and (max-width: ${theme.size.laptop}) {
        width: 100%;
        padding: 10rem 1rem 2rem 1rem;
      }
    `}
`;

export const Table = styled.table`
  margin-top: 2rem;

  grid-template-columns: ${({ view }) => handleGridTemplate(view)};
  display: grid;
  grid-template-rows: auto;

  ${({ scrolled }) =>
    scrolled &&
    css`
      // overflow: auto;
      width: 100%;
      @media (max-width: 1600px) {
        overflow: auto;
      }
    `}
`;

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  positon: relative;
`;

export const Sidebar = styled.div`
  padding: 10rem 2.2rem 0 2.2rem;
  width: 16.66666%;
  height: 100vh;
  overflow: auto;
  background: #f5e8df;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;

  @media screen and (max-width: ${theme.size.laptop}) {
    // display: none;
  }
  @media screen and (max-width: 1300px) {
    position: fixed;
    left: 0px;
    top: 80px;
    width: 300px;
    opacity: 1;

    padding: 5rem 2.8rem 5rem 2.8rem;
    background: #f5e8df;
    margin: 0;
    list-style: none;
    z-index: 1;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 5px;
  }
`;

export const Arrow = styled.div`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

export const ArrowCont = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 80px;
  left: 300px;
  background-color: #f5e8df;
  width: 4rem;
  height: 4rem;
  border-bottom-right-radius: 5px;

  opacity: 1;

  @media (min-width: 1300px) {
    display: none;
  }
`;

// export const Sidebar = styled.div`
//   padding: 2rem 2.8rem 0 2.8rem;
//   width: 250px;

//   min-height: calc(100vh - 8rem);
//   background: #f5e8df;
//   margin: 0;
//   list-style: none;
//   z-index: 2;

//   ${({ responsive }) =>
//     responsive &&
//     css`
//       @media screen and (max-width: ${theme.size.laptop}) {
//         // display: none;
//       }
//       @media screen and (max-width: 1300px) {
//         position: absolute;
//         left: 0px;
//         top: 80px;
//         width: 200px;
//         opacity: 1;

//         padding: 5rem 2.8rem 5rem 2.8rem;
//         background: #f5e8df;
//         margin: 0;
//         list-style: none;
//         z-index: 1;
//         display: flex;
//         flex-direction: column;
//         border-bottom-right-radius: 5px;
//       }
//     `}
// `;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Content = styled.div`
  padding: 10rem 3.5rem 2rem 310px;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;

  @media screen and (max-width: ${theme.size.laptop}) {
    padding: 10rem 1rem 2rem 1rem;
  }
`;

export const Filtry = styled.div`
  display: flex;
  min-height: 4.8rem;
  position: relative;
  font-size: 2.6rem;
  font-weight: 700;
  padding-left: 1.6rem;

  color: #80cf26;
  flex-direction: column;
  @media screen and (max-width: 1366px) {
    padding-left: 3rem;
  }
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  font-weight: ${({ head }) => (head ? 700 : 400)};
  margin-bottom: ${({ head }) => (head ? '1rem' : 0)};
  border-bottom: ${({ head }) => (head ? '2px solid #0b0b0b' : 'none')};
  grid-column: ${({ column3 }) => (column3 ? 'span 3' : '')};
`;

export const EditInput = styled.input`
  background-color: rgba(0, 0, 0, 0.03);
  &:hover {
    cursor: pointer;
  }
`;

export const StyledSelect = styled(Select)`
  flex-basis: 40%;
  margin-right: 2rem;
  height: 100%;
  border: 1px solid #80cf26;
  border-radius: 0.5rem;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

export const AddNew = styled(Button)`
  border-width: 0;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
`;

export const SidebarHeader = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.25em;
  color: #0b0b0b;
  padding: 1.2rem 2rem;
  border-bottom: 2px solid #0b0b0b;
  margin-bottom: 0.8rem;
  &:hover {
    cursor: pointer;
    color: #6d5cce;
  }
`;

export const Tab = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.25em;
  color: #0b0b0b;
  padding: 0.8rem 1rem;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease;
  border-color: ${({ active }) => (active ? '#6d5cce' : 'transparent')};
  &:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.3);
  }
  & + & {
    margin-top: 0.4rem;
  }
`;

export const WybranaFirma = styled.div`
  border-bottom: 2px solid #0b0b0b;
  display: flex;
  height: 4.8rem;
  position: relative;
  font-size: 2.4rem;
  font-weight: 700;
  margin-left: 3.2rem;
  &:before {
    position: absolute;
    content: '';
    background: #0b0b0b;
    height: 2px;
    width: 7.3rem;
    left: -7.3rem;
    bottom: -2px;
  }
`;

export const StyledLink = styled(Link)`
  grid-column: 1/8;
  grid-template-columns: 5rem 24rem 22rem 20rem 20rem 15rem 10rem;
  display: grid;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      grid-column: 1/8;
    `}
`;

export const StyledRow = styled.div`
  grid-column: 1/9;
  grid-template-columns: 5rem 24rem 22rem 20rem 20rem 15rem 10rem;
  display: flex;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export const TabLink = styled(Link)`
  display: block;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export const DodajEdytuj = styled.div`
  margin-left: 3.2rem;
  margin-top: 2rem;
  h3 {
    font-weight: 700;
    font-size: 1.6rem;
    text-transform: uppercase;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PojedynczyUzytkownik = styled(KontrahenciAdmin)`
  display: ${({ active }) => (active ? 'none' : 'block')};
`;

export const GodzinyWyjazdu = styled.div`
  margin-left: 3.2rem;
`;

export const GodzinaLabel = styled.label`
  margin-right: 2rem;
`;

export const Header = styled(Heading)`
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;

export const StyledTabListA = styled.div`
  padding: 10rem 2.8rem 0 2.8rem;
  width: 300px;
  height: 100%;
  min-height: calc(100vh - 8rem);
  background: #f5e8df;
  margin: 0;
  list-style: none;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  padding-bottom: 12rem !important;
  overflow-y: scroll;
  @media screen and (min-width: 1367px) {
    top: 0 !important;
    left: 0 !important;
  }
  @media screen and (max-width: 1366px) {
    position: fixed;
  }

  ${({ responsive }) =>
    responsive &&
    css`
      @media screen and (max-width: ${theme.size.laptop}) {
        // display: none;
      }
      @media screen and (max-width: 1366px) {
        position: fixed;
        left: 0px;
        top: 80px;
        width: 300px;
        opacity: 1;

        padding: 5rem 2.8rem 5rem 2.8rem;
        background: #f5e8df;
        margin: 0;
        list-style: none;
        z-index: 1;
        display: flex;
        flex-direction: column;
        border-bottom-right-radius: 5px;
      }
    `}
`;

export const StyledTabA = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.25em;
  color: #fff;
  padding: 1.2rem 2rem;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 6px;
  transition: all 0.3s ease;
  background: #000;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}

  &.react-tabs__tab--selected {
    background: #000;
    border-color: #000;
    color: #fff;
    border-radius: 6px;
  }
  &:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.3);
  }
  & + & {
    margin-top: 0.4rem;
  }
`;

export const AdminWrapperA = styled(Wrapper)`
  min-height: 100vh;
  position: relative;
`;

export const ArrowA = styled.div`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

export const ArrowContA = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 300px;
  background-color: #f5e8df;
  width: 4rem;
  height: 4rem;
  border-bottom-right-radius: 5px;
  z-index: 9;
  opacity: 1;
`;

export const ArrowContC = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 80px;
  left: 300px;
  background-color: #f5e8df;
  width: 4rem;
  height: 4rem;
  border-bottom-right-radius: 5px;

  opacity: 1;
  z-index: 9;
  @media (min-width: 1366px) {
    display: none;
  }
`;

export const StyledLinkWrapper = styled.div``;
