import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './SumaZamowienie.module.scss';

const SumaZamowienia = ({ razem, suma, rabatZupaZDaniem, rabatFirmy }) => (
  <>
    {rabatZupaZDaniem > 0 && (
      <Row className={styles.rowRazem}>
        <Col md={8} xs={6} className={styles.produktRazem}>
          Rabat na zupę w zestawie z daniem
        </Col>
        <Col md={2} xs={3} className={styles.iloscRazem}>
          {rabatZupaZDaniem}
        </Col>
        <Col md={2} xs={3} className={styles.cenaRazem}>
          {-rabatZupaZDaniem * (rabatFirmy > 0 ? 1 : 2)} {razem !== 0 ? ' zł' : ''}
        </Col>
      </Row>
    )}
    <Row className={styles.rowRazem}>
      <Col md={8} xs={6} className={styles.produktRazem}>
        razem
      </Col>
      <Col md={2} xs={3} className={styles.iloscRazem}>
        {razem}
      </Col>
      <Col md={2} xs={3} className={styles.cenaRazem}>
        {suma.toFixed(2)} {razem !== 0 ? ' zł' : ''}
      </Col>
    </Row>
  </>
);

SumaZamowienia.propTypes = {
  razem: PropTypes.number.isRequired,
  suma: PropTypes.number.isRequired,
  rabatZupaZDaniem: PropTypes.number.isRequired,
  rabatFirmy: PropTypes.number.isRequired,
};

export default SumaZamowienia;
