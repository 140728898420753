import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NawigacjaKlient from 'components/molecules/NawigacjaKlient/NawigacjaKlient';
import Heading from 'components/atoms/Heading/Heading';
import Logo from 'components/atoms/Logo/Logo';
import Logout from 'components/molecules/Logout/Logout';

const Header = styled.header`
  padding-top: 2rem;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const StyledLogo = styled(Logo)`
  width: 11rem;
  height: 11rem;
`;

const HeadingsWrapper = styled.div`
  text-align: center;
  @media (max-width: 991px) {
    order: 4;
    width: 100%;
  }
`;

const StyledH1 = styled(Heading)`
  font-size: 4rem;
  color: ${({ theme }) => theme.green};
`;

const StyledH2 = styled(Heading)`
  font-size: 2rem;
`;

const HeaderKlient = ({ title, subtitle, dataMenu }) => (
  <Header>
    <div className="container">
      <div className=" ">
        <div className="col-xxl-10 offset-xxl-1">
          <StyledWrapper>
            <StyledLogo path="/" />
            {/* <StyledLogo path="/zamow-posilek" /> */}
            <HeadingsWrapper>
              <StyledH1 rank={1}>{title}</StyledH1>
              <StyledH2 rank={2}>{subtitle}</StyledH2>
              {dataMenu && <span>Menu na dzień {dataMenu}</span>}
            </HeadingsWrapper>
            <NawigacjaKlient />
            <Logout />
          </StyledWrapper>
        </div>
      </div>
    </div>
  </Header>
);

HeaderKlient.propTypes = {
  dataMenu: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HeaderKlient.defaultProps = {
  dataMenu: '',
  title: '',
  subtitle: '',
};

export default HeaderKlient;
